import React, { useMemo } from 'react'
import useDetectDevice from 'use-mobile-detect-hook'
import ReportedCOVIDCases from 'modules/COVIDMap/shared/ReportedCases'

import DisclaimerModal from './components/DisclaimerModal'
import ModalController from '@therms/web-common/components/Modal/ModalController'
import ReportModal from './components/ReportModal'
// import SubscribeAlertsForm from './components/SubscribeAlertsForm'
import useLocalStorage from '../../hooks/useLocalStorage'

const index = () => {
  const [showingDisclaimer, setShowingDisclaimer] = useLocalStorage('disclaimer', true)

  const detectDevice = useDetectDevice()
  const isMobile = useMemo(() => detectDevice.isMobile(), [])

  return (
    <div id="map-screen" className="d-flex flex-column">
      {showingDisclaimer && <DisclaimerModal closeHandler={() => setShowingDisclaimer(false)} />}

      <div className="bg-dark d-flex justify-between p-3">
        <ModalController modal={ReportModal}>
          {({ show }) => (
            <button className="btn btn-danger" onClick={show}>
              Report COVID-19 Case
            </button>
          )}
        </ModalController>

        {!isMobile && <h1 className="mb-0 font-200">COVID-19 Community Reporting</h1>}
        {isMobile && (
          <div className="d-flex font-weight-bolder text-right">COVID-19 Community Reporting</div>
        )}

        {/* <ModalController modal={SubscribeAlertsForm}> */}
        {/*  {({ show }) => ( */}
        {/*    <button className="btn btn-primary" onClick={show}> */}
        {/*      Subscribe to Text Updates for my Area */}
        {/*    </button> */}
        {/*  )} */}
        {/* </ModalController> */}
      </div>

      <div className="">
        <ReportedCOVIDCases />
      </div>
    </div>
  )
}

export default index
