import React, { useEffect, useState } from 'react'
import TextWithSuggestions from '@therms/web-common/components/Inputs/TextWithSuggestions'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'

const TextLocationAutoComplete = ({ onChange, onLatLngChange }) => {
  const [addressOptions, setAddressOptions] = useState([])
  const [autoCompleteService, setAutoCompleteService] = useState()

  useEffect(() => {
    setAutoCompleteService(new window.google.maps.places.AutocompleteService())
  }, [])

  function handlePickSuggestion(googlePlaceId) {
    // console.log('handlePickSuggestion: ', googlePlaceId)

    // get the lat/lng of the googlePlaceId from the auto-complete option
    if (onLatLngChange) {
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode({ placeId: googlePlaceId }, (result) => {
        const lat = get(result, '0.geometry.location.lat')
        const lng = get(result, '0.geometry.location.lng')
        onLatLngChange({ lat: lat(), lng: lng() })
      })
    }

    if (onChange) onChange(googlePlaceId)
  }

  return (
    <TextWithSuggestions
      name="address"
      suggestions={addressOptions}
      onChange={streetAddress => {
        if (autoCompleteService) {
          autoCompleteService.getQueryPredictions({ input: streetAddress }, options => {
            // build the list of options using the google "place_id" as the option value
            const optionsList = (options || []).reduce((acc, option) => {
              acc.push({
                label: option.description,
                value: option.place_id,
              })

              return acc
            }, [])

            // required in Google's TOS to show "powered by google"
            setAddressOptions([...optionsList, { label: 'Powered By Google', value: '' }])
          })
        }
      }}
      onPickSuggestion={handlePickSuggestion}
      placeholder="Start typing a city, county, address..."
    />
  )
}

TextLocationAutoComplete.propTypes = {
  onChange: PropTypes.func,
  onLatLngChange: PropTypes.func,
}

TextLocationAutoComplete.defaultProps = {
  onChange: (...args) => {
    console.log(args)
  },
  onLatLngChange: (...args) => {
    console.log(args)
  },
}

export default TextLocationAutoComplete
