// babel polyfills
import 'core-js'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production')
  Sentry.init({ dsn: 'https://8b93ecac0bcf4b77ab441603f66d8835@sentry.io/5040559' })

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept()
}
