import axios from 'axios'
import cookies from 'js-cookie'

const axiosConfig = {
  baseURL: process.env.ADMIN_SERVER,
  headers: { 'Cache-Control': 'no-cache' },
  withCredentials: true,
}

const adminApi = axios.create(axiosConfig)

adminApi.interceptors.request.use(
  function(config) {
    // add jwt to req header
    // eslint-disable-next-line no-param-reassign
    config.headers[process.env.JWT_HEADER_KEY] = cookies.get(process.env.COVID_COOKIE_NAME)
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

export default adminApi

/**
 * When a user/visitor thinks a reported covid case shouldn't belong or is inaccurate they can "flag"
 * it.
 *
 * @param covidCaseId {String}
 * @param reason {String}
 * @returns {Promise<AxiosResponse<T>>}
 */
export const flagCovidCase = (covidCaseId, reason) =>
  adminApi.post(`/covid-public/flag-covid-case`, { covidCaseId, reason })

/**
 * Get reported covid cases (approved & posted by admin) for a geo spacial box by passing
 * the SouthWest and NorthEast lat/lng coords to the backend
 *
 * @param swLat {Number} SouthWest latitude
 * @param swLng {Number} SouthWest longitude
 * @param neLat {Number} NorthEast latitude
 * @param neLng {Number} NorthEast longitude
 * @param thermsCoreUserId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getReportedCovidCases = ({ swLat, swLng, neLat, neLng }) =>
  adminApi.get(
    `/covid-public/get-reported-covid-cases?swLat=${swLat}&swLng=${swLng}&neLat=${neLat}&neLng=${neLng}`,
  )

/**
 * Get a CovidUser record either by covidUserId or thermsCoreUserId
 *
 * @param [covidUserId] {String}
 * @param [thermsCoreUserId] {String}
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getCovidUser = ({ covidUserId, thermsCoreUserId } = {}) =>
  adminApi.get(
    `/covid-public/get-covid-user?covidUserId=${covidUserId}&thermsCoreUserId=${thermsCoreUserId}`,
  )

/**
 * Report a case of covid
 *
 * @param covidUserId {String}
 * @param details {String}
 * @param lat {Number} Latitude on map
 * @param lng {Number} Longitude on map
 * @returns {Promise<AxiosResponse<T>>}
 */
export const reportCovidCase = ({ covidUserId, details, lat, lng } = {}) =>
  adminApi.post(`/covid-public/report-covid-case`, { covidUserId, details, lat, lng })

/**
 * Sends a confirmation code to a user's email OR phone. This information (name, phone, email) will
 * be used to create the CovidUser record when the code is confirmed.
 *
 * @param email {String}
 * @param name {String}
 * @param phone {Phone}
 * @returns {Promise<AxiosResponse<T>>}
 */
export const sendCovidUserConfirmation = ({ email, name, phone } = {}) =>
  adminApi.post(`/covid-public/send-covid-user-confirmation`, { email, name, phone })

/**
 * Verify the confirmation code sent to a user, the CovidUser will be returned on successfl confirmation
 *
 * @param code {Number}
 * @returns {Promise<AxiosResponse<T>>}
 */
export const verifyConfirmation = code =>
  adminApi.post(`/covid-public/verify-confirmation`, { code })
