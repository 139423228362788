import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Circle, InfoBox, Marker } from '@react-google-maps/api'
import DateTime from '@therms/web-common/components/DateTime'

const CovidCaseMarker = ({ covidCase }) => {
  const [showInfoBox, setShowInfoBox] = useState(false)

  const { geo } = covidCase || {}

  useEffect(() => {
    let t

    if (showInfoBox)
      t = setTimeout(() => {
        setShowInfoBox()
      }, 5000)

    return () => clearTimeout(t)
  }, [showInfoBox])

  if (!geo) return null

  const position = { lat: geo.coordinates[1], lng: geo.coordinates[0] }

  return (
    <>
      <Circle
        center={position}
        options={{
          fillColor: 'rgba(245,5,0,0.5)',
          strokeColor: 'rgba(166,0,16,0.76)',
        }}
        radius={250}
      />
      {showInfoBox && (
        <InfoBox position={position} onCloseClick={() => setShowInfoBox()}>
          <div className="card p-2">
            <div>
              <strong>COVID-19</strong> reported <DateTime relativeTime timestamp={covidCase.createdAt} />
            </div>

            <div className="mt-2">{/*  todo: allow flagging */}</div>
          </div>
        </InfoBox>
      )}
      <Marker onClick={setShowInfoBox} position={position} />
    </>
  )
}

CovidCaseMarker.propTypes = {
  covidCase: PropTypes.object.isRequired,
}
CovidCaseMarker.defaultProps = {}

export default CovidCaseMarker
