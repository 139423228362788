import React, { useState } from 'react'
import PropTypes from 'prop-types'

import CodeSender from './components/CodeSender'
import CodeConfirmatory from './components/CodeConfirmatory'

const index = ({ onComplete }) => {
  const [expectingCode, setExpectingCode] = useState(false)

  if (expectingCode) return <CodeConfirmatory onComplete={onComplete} />

  return <CodeSender setCodeSent={setExpectingCode} />
}

export default index

index.propTypes = {
  onComplete: PropTypes.func.isRequired,
}