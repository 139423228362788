import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useCookies from 'hooks/useCookies'

import Modal from '@therms/web-common/components/Modal'
import ReportCOVIDForm from '../../ReportCOVIDForm'
import UserAuth from 'modules/UserAuth'

const ReportModal = ({ closeHandler }) => {
  const { get: getCookie } = useCookies()
  const [, forceUpdate] = useState() // calling the "setState" forces the component to re-render

  const showReportForm = getCookie('covid_user')

  const body = showReportForm ? (
    <ReportCOVIDForm onComplete={closeHandler} />
  ) : (
    <UserAuth onComplete={forceUpdate} />
  )

  return (
    <Modal closeHandler={closeHandler} lg={showReportForm} title="Report COVID-19 Case">
      {body}
    </Modal>
  )
}

ReportModal.propTypes = {
  closeHandler: PropTypes.func,
}

ReportModal.defaultProps = {
  closeHandler: () => {},
}

export default ReportModal
