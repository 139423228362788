import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ReportLocation from '../COVIDMap/shared/ReportLocation'
import Textarea from '@therms/web-common/components/Inputs/Textarea'
import { reportCovidCase } from '../../services/adminApi'
import useCookies from '../../hooks/useCookies'
import Loading from '@therms/web-common/components/Loading'

const ReportCOVID = ({ onComplete }) => {
  const covidUserId = useCookies().get('covid_user')
  const [comments, setComments] = useState('')
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [mapLatLng, setMapLatLng] = useState()

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setError()
      setLoading(true)
      await reportCovidCase({
        covidUserId,
        details: comments,
        ...mapLatLng,
      })
      onComplete()
    } catch (err) {
      setError(err)
    } finally {
      setLoading()
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="justify-content-center " />

      <div className="mb-3">
        <ReportLocation onLatLngChange={setMapLatLng} value={mapLatLng} />
      </div>

      <div className="justify-content-center mb-3">
        <Textarea
          placeholder="Details, references, comments, etc."
          name="comments"
          onChange={e => setComments(e.target.value)}
          value={comments}
        />
      </div>

      {error && (
        <div className="alert alert-danger">
          There was a problem submitting your case, please try again. If you continue to experience
          issues please email <a href="mailto:support@therms.io">support@therms.io</a>
        </div>
      )}

      {loading && <Loading />}

      <div className="d-flex justify-content-center mb-3">
        <input type="submit" className="btn btn-block btn-primary" />
      </div>
    </form>
  )
}

ReportCOVID.propTypes = {
  onComplete: PropTypes.func.isRequired,
}

ReportCOVID.defaultProps = {}

export default ReportCOVID
