import Cookies from 'js-cookie'

const useCookies = () => {
  const get = name => Cookies.get(name)

  const set = (name, value) => Cookies.set(name, value)

  return { get, set }
}

export default useCookies
