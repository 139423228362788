import React from 'react'
import PropTypes from 'prop-types'

import Modal from '@therms/web-common/components/Modal'

const DisclaimerModal = ({ closeHandler }) => {
  const footer = (
    <p className="text-center">
      For the latest information on the coronavirus, including symptoms, how to protect yourself,
      and travel advisories, visit the{' '}
      <a href="https://www.cdc.gov/coronavirus/2019-nCoV/index.html">CDC's website</a>.
    </p>
  )

  return (
    <Modal footer={footer} title="Disclaimer" lg>
      <div className="alert alert-warning text-center">
        Please keep in mind that these COVID-19 reported cases are submitted by the community
      </div>

      <p className="text-justify">
        <hr />
        The purpose of this map is for public safety and security professionals to share information
        and knowledge of COVID-19 cases.
        <hr />
        Our goal within the THERMS team is to help public safety and security professionals stay
        safe while on-duty in a private or public location.
      </p>

      <div className="row justify-content-center">
        <button className="btn btn-primary" onClick={closeHandler}>
          I Understand
        </button>
      </div>
    </Modal>
  )
}

DisclaimerModal.propTypes = {
  closeHandler: PropTypes.func,
}

DisclaimerModal.defaultProps = {
  closeHandler: () => {},
}

export default DisclaimerModal
