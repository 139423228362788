import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useCookies from 'hooks/useCookies'

import { verifyConfirmation } from 'services/adminApi'

import Text from '@therms/web-common/components/Inputs/Text'

const CodeConfirmatory = ({ onComplete }) => {
  const cookies = useCookies()
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const { data } = await verifyConfirmation(code)

      cookies.set('covid_user', data.id)
      onComplete(data.id)
    } catch (err) {
      console.log(err)
      setError(true)
    }
  }

  if (error)
    return (
      <div>
        <p className="text-center text-danger">There was a problem, please try again</p>

        <div className="d-flex justify-content-center">
          <button className="btn btn-primary" onClick={() => setError(false)}>
            Retry
          </button>
        </div>
      </div>
    )

  return (
    <div>
      <h2 className="font-150 text-center">Code Sent</h2>
      <p className="text-center">
        If the code hasn't arrive after 5 minutes, we suggest you try resending your code. <br />
        <a href="mailto:support@therms.io">Contact us</a> if the problem persists.
      </p>

      <form name="code-verification" onSubmit={handleSubmit}>
        <Text
          label="Confirmation Code"
          name="confirmation-code"
          onChange={e => setCode(e.target.value)}
          placeholder="682982"
          required
          value={code}
        />

        <div className="d-flex justify-content-center">
          <input className="btn btn-primary" type="submit" value="Confirm" />
        </div>
      </form>
    </div>
  )
}

CodeConfirmatory.propTypes = {
  onComplete: PropTypes.func.isRequired,
}

export default CodeConfirmatory
