import { getReportedCovidCases } from '../services/adminApi'
import { useEffect, useState } from 'react'

export default function useReportedCasesForMapBounds({ neLat, neLng, swLat, swLng } = {}) {
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [covidCases, setCovidCases] = useState([])

  async function fetchCovidCases({ fetchInBackground = true }) {
    setError()

    if (neLat && neLng && swLat && swLng) {
      if (!fetchInBackground) setLoading(true)

      getReportedCovidCases({ neLat, neLng, swLat, swLng })
        .then(response => {
          setCovidCases(response.data || [])
        })
        .catch(setError)
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    fetchCovidCases({ fetchInBackground: false })
  }, [neLat, neLng, swLat, swLng])

  useEffect(() => {
    let t

    if (error) {
      t = setTimeout(() => {
        fetchCovidCases({ fetchInBackground: false })
      }, 2000)
    }

    return () => clearTimeout(t)
  }, [error])

  return {
    covidCases,
    error,
    loading,
    refetch: fetchCovidCases,
  }
}
