export function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          accuracy: position.coords.accuracy,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      },
      reject,
      {
        enableHighAccuracy: true,
        maximumAge: 0,
      },
    )
  })
}
