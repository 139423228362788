// a map that saves the users zoom/settings when reopening
//  optionally shows all or a list of locations & users
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap } from '@react-google-maps/api'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { debounce } from 'lodash-es'
import useReportedCasesForMapBounds from '../../../hooks/useReportedCasesForMapBounds'
import CovidCaseMarker from '../components/CovidCaseMarker'
import LayoverRelative from '@therms/web-common/components/Layover/Relative'
import Loading from '@therms/web-common/components/Loading'
import AppContext from '../../../AppContext'
import useDetectDevice from 'use-mobile-detect-hook'

const makeLSKey = x => `ReportedCasesMap_${x}`

const ReportedCasesMap = ({ initialCenter, initialZoom }) => {
  const appContext = useContext(AppContext)

  const detectDevice = useDetectDevice()
  const isMobile = useMemo(() => detectDevice.isMobile(), [])

  const [swNeCorners, setSwNeCorners] = useLocalStorage(makeLSKey('swNeBounds'))
  const [height, setHeight] = useState(isMobile ? '85vh' : '90vh')
  const [center, , setCenterWithoutUpdate] = useLocalStorage(
    makeLSKey(`center`),
    appContext.geolocation || initialCenter,
  )
  const [mapType, setMapType] = useLocalStorage(makeLSKey(`mapType`), 'hybrid')
  const [zoom, setZoom] = useLocalStorage(
    makeLSKey(`zoom`),
    appContext.geolocation?.accuracy < 5000 ? 13 : initialZoom,
  )

  const containerRef = useRef()

  useEffect(() => {
    setHeight(`${containerRef.current?.clientHeight ?? 420}px`)
  }, [])

  const { covidCases, loading } = useReportedCasesForMapBounds(swNeCorners)

  const onLoad = map => {
    map.addListener(
      'center_changed',
      debounce(() => {
        setCenterWithoutUpdate({ lat: map.center.lat(), lng: map.center.lng() })
      }, 500),
    )

    map.addListener(
      'bounds_changed',
      debounce(() => {
        const ne = map?.getBounds()?.getNorthEast()
        const sw = map?.getBounds()?.getSouthWest()

        setSwNeCorners({ neLat: ne?.lat(), neLng: ne?.lng(), swLat: sw?.lat(), swLng: sw?.lng() })
      }, 250),
    )

    map.addListener('maptypeid_changed', () => {
      setMapType(map.getMapTypeId())
    })

    map.addListener('zoom_changed', () => {
      setZoom(map.zoom)
    })
  }

  return (
    <div className="h-100" ref={containerRef}>
      {loading && (
        <LayoverRelative>
          <Loading />
        </LayoverRelative>
      )}

      <GoogleMap
        mapContainerStyle={{
          height,
          width: '100%',
        }}
        onLoad={onLoad}
        zoom={zoom}
        center={center}
        options={{
          mapTypeId: mapType,
        }}
      >
        {covidCases.map(covidCase => (
          <CovidCaseMarker covidCase={covidCase} />
        ))}
      </GoogleMap>
    </div>
  )
}

ReportedCasesMap.propTypes = {
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  initialZoom: PropTypes.number,
}
ReportedCasesMap.defaultProps = {
  initialCenter: { lat: 41.2040073, lng: -96.5509926 },
  initialZoom: 5,
}

export default ReportedCasesMap
