import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { pickBy } from 'lodash-es'

import { sendCovidUserConfirmation } from 'services/adminApi'

import EmailPhoneSwitcher from 'components/EmailPhoneSwitcher'
import Text from '@therms/web-common/components/Inputs/Text'

const CodeSender = ({ setCodeSent }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const [error, setError] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      await sendCovidUserConfirmation(
        pickBy({
          email,
          name,
          phone,
        }),
      )

      setCodeSent(true)
    } catch (err) {
      console.log(err)
      setError(true)
    }
  }

  if (error)
    return (
      <div>
        <p className="text-center text-danger">There was a problem, please try again</p>

        <div className="d-flex justify-content-center">
          <button className="btn btn-primary" onClick={() => setError(false)}>
            Retry
          </button>
        </div>
      </div>
    )

  return (
    <div>
      <div className="text-center">
        <h2 className="font-150">We need to confirm your identity</h2>

        <p>
          After sending you a verification code via email or phone you will be able to submit your
          report
        </p>
      </div>

      <form name="user-auth" onSubmit={handleSubmit}>
        <Text
          label="Name"
          name="name"
          onChange={e => setName(e.target.value)}
          placeholder="John Doe"
          required
          value={name}
        />

        <EmailPhoneSwitcher email={email} phone={phone} setEmail={setEmail} setPhone={setPhone} />

        <div className="d-flex justify-center mb-3">
          <input className="btn btn-primary" type="submit" value="Send Code" />
        </div>
      </form>
    </div>
  )
}

CodeSender.propTypes = {
  setCodeSent: PropTypes.func.isRequired,
}

export default CodeSender
