import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Circle, GoogleMap, Marker } from '@react-google-maps/api'
import useDetectDevice from 'use-mobile-detect-hook'

import './ReportLocation.scss'
import AppContext from '../../../AppContext'
import TextLocationAutoComplete from '../../../components/TextLocationAutoComplete'

const ReportLocation = ({ onLatLngChange }) => {
  const appContext = useContext(AppContext)

  const detectDevice = useDetectDevice()
  const isMobile = useMemo(() => detectDevice.isMobile(), [])

  const [googleMap, setGoogleMap] = useState()
  const [mapLatLng, setMapLatLng] = useState()

  useEffect(() => {
    if (mapLatLng?.lat && mapLatLng?.lng && googleMap) {
      googleMap.panTo(mapLatLng)
      onLatLngChange(mapLatLng)
    }
  }, [googleMap, mapLatLng])

  const initialCenter = appContext.geolocation || { lat: 41.2040073, lng: -96.5509926 }

  useEffect(() => {
    if (initialCenter) {
      onLatLngChange(initialCenter)
    }
  }, [])

  return (
    <div>
      <div className="alert alert-info mb-1 text-center">
        Drag the marker on the map to pick a location or type a location in the input
      </div>

      <div className="my-1" style={{ zIndex: 1 }}>
        <TextLocationAutoComplete onLatLngChange={setMapLatLng} />
      </div>

      <GoogleMap
        mapContainerStyle={{
          height: isMobile ? '350px' : '400px',
          width: '100%',
          zIndex: 0,
        }}
        zoom={appContext.geolocation?.accuracy < 5000 ? 13 : 4}
        center={mapLatLng?.lat && mapLatLng?.lng ? mapLatLng : initialCenter}
        onLoad={setGoogleMap}
        options={{
          mapTypeId: 'hybrid',
          tilt: 0,
        }}
      >
        <Marker
          draggable
          onDragEnd={e => {
            setMapLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          }}
          position={mapLatLng?.lat && mapLatLng?.lng ? mapLatLng : initialCenter}
        />
        <Circle
          center={mapLatLng?.lat && mapLatLng?.lng ? mapLatLng : initialCenter}
          options={{
            fillColor: 'rgba(245,5,0,0.5)',
            strokeColor: 'rgba(166,0,16,0.76)',
          }}
          radius={250}
        />
      </GoogleMap>
    </div>
  )
}

ReportLocation.propTypes = {
  onLatLngChange: PropTypes.func.isRequired,
}

ReportLocation.defaultProps = {}

export default ReportLocation
