import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Text from '@therms/web-common/components/Inputs/Text'

const EmailPhoneSwitcher = ({ email, phone, setEmail, setPhone }) => {
  const [activeTab, setActiveTab] = useState('email')

  const handleEmailClick = () => {
    setPhone('')
    setActiveTab('email')
  }

  const handlePhoneClick = () => {
    setEmail('')
    setActiveTab('phone')
  }

  const activeField =
    activeTab === 'email' ? (
      <Text
        name="email"
        placeholder="john.doe@therms.io"
        onChange={e => setEmail(e.target.value)}
        required
        type="email"
        value={email}
      />
    ) : (
      <Text
        name="phone"
        placeholder="555 555-5555"
        onChange={e => setPhone(e.target.value)}
        required
        type="tel"
        value={phone}
      />
    )

  return (
    <div>
      <div className="btn-group d-flex mb-2">
        <button
          type="button"
          className={`btn flex-1 btn-short btn-${activeTab === 'email' ? 'primary' : 'secondary'}`}
          onClick={handleEmailClick}
        >
          Email
        </button>

        <button
          type="button"
          className={`btn flex-1 btn-short btn-${activeTab === 'phone' ? 'primary' : 'secondary'}`}
          onClick={handlePhoneClick}
        >
          Phone
        </button>
      </div>

      <div>{activeField}</div>
    </div>
  )
}

EmailPhoneSwitcher.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  setEmail: PropTypes.func,
  setPhone: PropTypes.func,
}

EmailPhoneSwitcher.defaultProps = {
  email: '',
  phone: '',
  setEmail: () => {},
  setPhone: () => {},
}

export default EmailPhoneSwitcher
