import React, { useEffect, useState } from 'react'

import LayoutWrapper from 'modules/Layout/shared/LayoutWrapper'
import { SwrAppWrapper } from './services/swr'

import 'styles/index.scss'
import AppContext from './AppContext'
import { getCurrentLocation } from './services/geolocation'
import Loading from '@therms/web-common/components/Loading'

export default () => {
  // HTML5 geolocation API
  const [geolocation, setGeolocation] = useState() // { accuracy, lat, lng }
  const [loadingLocation, setLoadingLocation] = useState(true)

  useEffect(() => {
    getCurrentLocation()
      .then(setGeolocation)
      .catch(console.warn)
      .finally(() => setLoadingLocation())
  }, [])

  return (
    <AppContext.Provider
      value={{
        geolocation,
        setGeolocation,
      }}
    >
      <SwrAppWrapper>
        {loadingLocation ? <Loading text="Attempting to get your location" /> : <LayoutWrapper />}
      </SwrAppWrapper>
    </AppContext.Provider>
  )
}
